import { useTrackingInstance } from '@rategravity/1pt-lib';
import { FullWidthField, GetStartedLink, ShopCTAButton } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import { OwnUpTextStyle } from '@rategravity/own-up-component-library-legacy/components/typography/text/style';
import React from 'react';
import AnkleSmall from '../../../images/ankle-small.svg';
import Ankle from '../../../images/ankle.svg';
import CheckCircle from '../../../images/icons/check-circle.svg';
import NoCost from '../../../images/icons/no-cost.svg';
import NoSpam from '../../../images/icons/no-spam.svg';
import { colors } from '../../../modules/colors';
import { onboarding } from '../../../modules/navigation';
import { BoldText, Header } from '../../typography';

const WrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url(${Ankle})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionX: 'center',
  padding: '100px 0',
  variants: {
    large: {
      '@media screen and (max-width: 1440px)': {
        backgroundSize: '1440px'
      }
    },
    smallAndDown: {
      backgroundImage: `url(${AnkleSmall})`
    }
  }
});

const Wrapper = createOwnUpComponent(OwnUpBox, WrapperStyle);

const HeaderWrapperStyle = createOwnUpStyle({
  margin: '0 auto 2px',
  textAlign: 'center',
  variants: {
    medium: {
      maxWidth: '600px'
    },
    smallAndDown: {
      maxWidth: '400px'
    }
  }
});

const HeaderWrapper = createOwnUpComponent(OwnUpBox, HeaderWrapperStyle);

const TextWrapperStyle = createOwnUpStyle({
  margin: '0 auto 24px',
  textAlign: 'center'
});

const TextWrapper = createOwnUpComponent(OwnUpBox, TextWrapperStyle);

const ButtonWrapperStyle = createOwnUpStyle({
  width: '209px',
  margin: ' 0 auto 24px',
  variants: {
    smallAndDown: {
      width: '85%'
    }
  }
});

const ButtonWrapper = createOwnUpComponent(OwnUpBox, ButtonWrapperStyle);

const CTAButtonStyle = createOwnUpStyle({
  backgroundColor: colors.BLUE,
  color: colors.WHITE,
  width: '100%',
  cursor: 'pointer',
  ':hover': {
    color: colors.WHITE,
    backgroundColor: colors.BLUE_ALT
  }
});

export const CTAButton = createOwnUpComponent(ShopCTAButton, CTAButtonStyle);

const BulletsWrapperStyle = createOwnUpStyle({
  ...OwnUpTextStyle,
  margin: '0 auto',
  paddingInlineStart: 0,
  '& > li': {
    display: 'flex',
    listStyle: 'none',
    padding: '8px 0',
    ':before': {
      content: "''",
      display: 'inline-block',
      margin: '-2px 14px 0 0',
      backgroundSize: 'contain',
      minWidth: '20px'
    },
    ':nth-child(1)': {
      ':before': {
        background: `url(${NoSpam}) no-repeat top center `
      }
    },
    ':nth-child(2)': {
      ':before': {
        background: `url(${CheckCircle}) no-repeat top center `
      }
    },
    ':nth-child(3)': {
      ':before': {
        background: `url(${NoCost}) no-repeat top center `
      }
    }
  }
});

const BulletsWrapper = createOwnUpComponent('ul', BulletsWrapperStyle);

const Bullets = () => (
  <BulletsWrapper>
    {['No spam, ever', 'No hard credit checks', 'No cost or sales pressure'].map((text, i) => (
      <li key={i}>{text}</li>
    ))}
  </BulletsWrapper>
);

export const CTA = ({
  nerdWallet = false,
  urlParams
}: {
  nerdWallet?: boolean;
  urlParams?: Record<string, string>;
}) => {
  const trackingEvent = {
    hook: useTrackingInstance(),
    name: `fybm2-cta-${nerdWallet ? 'nerdwallet' : 'default'}-getstarted`,
    properties: { ...(nerdWallet ? { urlParams } : {}) }
  };

  return (
    <section aria-label="Get Started">
      <FullWidthField backgroundColor={colors.WHITE} desktopMaxWidth={1280} mobileLateralGutter={0}>
        <Wrapper>
          <HeaderWrapper>
            <Header>Ready to get your personalized rates?</Header>
          </HeaderWrapper>
          <TextWrapper>
            <OwnUpText>
              Build your profile in just <BoldText variant="cta">four minutes</BoldText>.
            </OwnUpText>
          </TextWrapper>
          <ButtonWrapper>
            <GetStartedLink
              onboarding={onboarding}
              trackingEvent={trackingEvent}
              url={`${process.env.GATSBY_ONBOARDING}`}
            >
              <CTAButton>Get Started</CTAButton>
            </GetStartedLink>
          </ButtonWrapper>
          <Bullets />
        </Wrapper>
      </FullWidthField>
    </section>
  );
};
